import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const CraftKingdomBrewery2 = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Craft Kingdom Brewery v2",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #691d02, #661b00, #5c1901, #5c1800, #451200 )",
        icons: "#661b00",
        navClass: "craft-kingdom-brewery-2",
        ogImage: require("../../assets/img/portfolio/ckb-2_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/craft-kingdom-brewery-2/",
      }}
    >
      <PortfolioHeader name="ckb-2" height="350" />
      <section className="container-fluid ckb-2-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Craft Kingdom Brewery</h1>
              <ul>
                <li>Etykiety</li>
                <li>Przygotowanie plików do druku</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque in vehicula leo, vitae porta nulla. Etiam sodales
                nulla tortor, sed egestas leo sodales a. Nullam dictum, quam
                vitae vulputate tempor, nunc augue tempor mauris, eu dignissim
                purus eros sodales nisi. Maecenas ac justo id urna tincidunt
                pellentesque euismod non odio. Pellentesque rutrum, sapien ac
                mattis blandit, ex est bibendum justo, et condimentum justo
                tellus id nulla. Nullam pharetra sed enim at vehicula.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid ckb-2-section-2">
        <div className="row">
          <div className="col-md-7">
            <div className="color-scheme"></div>
            <img
              src={require("../../assets/img/portfolio/ckb-2_top_logo.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-5">
            <h3>Logo</h3>
            <div className="row">
              <div className="col-sm-6">
                <p>Black CMYK:</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                  <span>100</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>White CMYK:</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>255</span>
                  <span>255</span>
                  <span>255</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>ffffff</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid ckb-2-section-3">
        <div className="inner">
          <h3>Przykładowy nagłówek 3</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed
            molestie lectus. Phasellus leo magna, faucibus bibendum mi quis,
            imperdiet dignissim orci. Sed ullamcorper sapien id congue
            convallis. Mauris ac magna vitae tortor ultrices tristique. Proin
            malesuada odio nunc. Fusce suscipit fermentum sapien, sed faucibus
            ipsum. Etiam dictum ut nisi nec eleifend. Nullam tincidunt dui id
            rutrum volutpat. Nam ac mauris venenatis, accumsan quam quis,
            aliquet leo. Etiam sit amet vestibulum ante.
          </p>
          <p>
            Vivamus magna sem, dapibus quis diam vel, lacinia tempus felis.
            Morbi cursus, est non mollis fermentum, dui mi blandit nibh, in
            interdum est felis vel lectus. Interdum et malesuada fames ac ante
            ipsum primis in faucibus.
          </p>
        </div>
      </section>
      <section className="container-fluid ckb-2-section-4">
        <div className="inner">
          <h3>Przykładowy nagłówek 4</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed
            molestie lectus. Phasellus leo magna, faucibus bibendum mi quis,
            imperdiet dignissim orci. Sed ullamcorper sapien id congue
            convallis. Mauris ac magna vitae tortor ultrices tristique. Proin
            malesuada odio nunc. Fusce suscipit fermentum sapien, sed faucibus
            ipsum.
          </p>
          <p>
            Etiam dictum ut nisi nec eleifend. Nullam tincidunt dui id rutrum
            volutpat. Nam ac mauris venenatis, accumsan quam quis, aliquet leo.
            Etiam sit amet vestibulum ante.
          </p>
          <p>
            Vivamus magna sem, dapibus quis diam vel, lacinia tempus felis.
            Morbi cursus, est non mollis fermentum, dui mi blandit nibh, in
            interdum est felis vel lectus. Interdum et malesuada fames.
          </p>
        </div>
      </section>
      <section className="container-fluid ckb-2-section-5">
        <div class="row">
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_1.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_2.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_3.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_4.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_5.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_6.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_7.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-sm">
            <img
              src={require("../../assets/img/portfolio/ckb-2_section_5_8.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid ckb-2-section-6">
        <div className="inner">
          <h3>Przykładowy nagłówek 6</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed
            molestie lectus. Phasellus leo magna, faucibus bibendum mi quis,
            imperdiet dignissim orci. Sed ullamcorper sapien id congue
            convallis. Mauris ac magna vitae tortor ultrices tristique. Proin
            malesuada odio nunc. Fusce suscipit fermentum sapien, sed faucibus
            ipsum.
          </p>
          <p>
            Etiam dictum ut nisi nec eleifend. Nullam tincidunt dui id rutrum
            volutpat. Nam ac mauris venenatis, accumsan quam quis, aliquet leo.
            Etiam sit amet vestibulum ante.
          </p>
          <p>
            Vivamus magna sem, dapibus quis diam vel, lacinia tempus felis.
            Morbi cursus, est non mollis fermentum, dui mi blandit nibh, in
            interdum est felis vel lectus. Interdum et malesuada fames.
          </p>
        </div>
      </section>
      <section className="container-fluid ckb-2-section-beer-divider">
        <img
          src={require("../../assets/img/portfolio/ckb-2_section_6_5.png")}
          className="img-fluid beer-image"
          alt=""
        />
      </section>
      <section className="container-fluid ckb-2-section-7">
        <div className="inner">
          <h3>Przykładowy nagłówek 7</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed
            molestie lectus. Phasellus leo magna, faucibus bibendum mi quis,
            imperdiet dignissim orci. Sed ullamcorper sapien id congue
            convallis. Mauris ac magna vitae tortor ultrices tristique. Proin
            malesuada odio nunc. Fusce suscipit fermentum sapien, sed faucibus
            ipsum.
          </p>
          <p>
            Etiam dictum ut nisi nec eleifend. Nullam tincidunt dui id rutrum
            volutpat. Nam ac mauris venenatis, accumsan quam quis, aliquet leo.
            Etiam sit amet vestibulum ante.
          </p>
          <p>
            Vivamus magna sem, dapibus quis diam vel, lacinia tempus felis.
            Morbi cursus, est non mollis fermentum, dui mi blandit nibh, in
            interdum est felis vel lectus. Interdum et malesuada fames.
          </p>
        </div>
        <img
          src={require("../../assets/img/portfolio/ckb-2_section_7_1.png")}
          className="img-fluid"
          alt=""
        />
      </section>
      <section className="container-fluid ckb-2-section-8">
        <div className="inner">
          <h3>Przykładowy nagłówek 8</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed
            molestie lectus. Phasellus leo magna, faucibus bibendum mi quis,
            imperdiet dignissim orci. Sed ullamcorper sapien id congue
            convallis. Mauris ac magna vitae tortor ultrices tristique. Proin
            malesuada odio nunc. Fusce suscipit fermentum sapien, sed faucibus
            ipsum.
          </p>
          <p>
            Etiam dictum ut nisi nec eleifend. Nullam tincidunt dui id rutrum
            volutpat. Nam ac mauris venenatis, accumsan quam quis, aliquet leo.
            Etiam sit amet vestibulum ante.
          </p>
          <p>
            Vivamus magna sem, dapibus quis diam vel, lacinia tempus felis.
            Morbi cursus, est non mollis fermentum, dui mi blandit nibh, in
            interdum est felis vel lectus. Interdum et malesuada fames.
          </p>
        </div>
      </section>
      <section className="container-fluid ckb-2-section-9">
        <img
          src={require("../../assets/img/portfolio/ckb-2_section_9_1.png")}
          className="img-fluid poligraphy"
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default CraftKingdomBrewery2;
